import React, { useEffect, useState } from 'react';
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
import { RoleBack } from '../../types/Role';

interface RoleBasedComponentProps {
  roleName: string[]; // Array of roles to hide the children for
  children?: React.ReactNode;
}

/**
 * RoleBasedComponent:
 * This component conditionally renders its children based on the current user's role.
 * It takes an array of role names (`roleName`) as a prop and checks if the current user's role matches any of them.
 * 
 * - If the user's role matches one of the roles in `roleName`, the component will hide its children.
 * - If the user's role does not match any of the roles in `roleName`, the component will render its children.
 * 
 * This is useful for hiding specific UI elements from users with certain roles.
 * 
 * Props:
 * - roleName: An optional array of role names for which the children should be hidden. Defaults to `[RoleBack.SUPERADMIN]`.
 * - children: The React elements to conditionally render.
 */
const RoleBasedComponent: React.FC<RoleBasedComponentProps> = ({
  roleName = [RoleBack.SUPERADMIN],
  children,
}) => {
  const [allow, setAllow] = useState(true); // Start with true to allow by default
  const { currentUser } = useCurrentUserState();

  useEffect(() => {
    if (currentUser) {
      try {
        if (roleName.includes(currentUser.role.name)) {
          setAllow(false); // Set to false if user role matches any roleName
        } else {
          setAllow(true); // Set to true otherwise
        }
      } catch (error) {
        console.log('Error decoding token: ', error);
      }
    }
  }, [roleName, currentUser]);

  return <>{allow ? children : null}</>; // Render children only if allow is true
};

export default RoleBasedComponent;
