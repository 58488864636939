import React, { useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import Loader from '../../../components/loader/Loader';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import SensorCard from '../../../components/card/SensorCard';
import { SensorInfo } from '../../../workers/device-data-worker/dataWorker';
import LineChart from '../../../components/chart/line-chart/LineChart';

interface DashboardTempHumSingleProps {
  deviceState: Device;
}

interface SensorData {
  [key: string]: string | number;
}

interface ResponseData {
  deviceData: SensorData[];
}

const DashboardTempHumSingle: React.FC<DashboardTempHumSingleProps> = ({
  deviceState,
}) => {
  const [data, setData] = useState<SensorData[]>([]);
  const [dashBoardSts, setDashboardSts] = useState<SensorData>();
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const workerRefSensor = useRef<Worker | null>(null);
  const workerRefLineChart = useRef<Worker | null>(null);
  const [viewMode, setViewMode] = useState<'graphTemp' | 'graphHum'>('graphTemp');
  const [loading, setLoading] = useState(false);
  const [tempHumSingleLineChart, setTempHumSingleLineChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });


  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
      );
      const dataAsSensor = response as ResponseData;
      setData(dataAsSensor.deviceData);
      setDashboardSts(dataAsSensor.deviceData[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);

    }
  };

  useEffect(() => {
    workerRefSensor.current = new Worker(
      new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
    );
    workerRefSensor.current.onmessage = function (e) {
      const { sensorInfo } = e.data;
      setSensorInfo(sensorInfo);

    };

    workerRefLineChart.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefLineChart.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setTempHumSingleLineChart({ seriesData, categories })
    };
  }, []);

  const handleButtonClick = (mode: 'graphTemp' | 'graphHum') => {
    setViewMode(mode);
  };

  console.log("sensor", sensorInfo);


  useEffect(() => {
    if (workerRefLineChart.current && data.length > 0) {
      const names: string[] = [];
      const keyNames: string[] = [];
      const colors = ['117DAE', 'DD0000'];

      if (sensorInfo?.Temperature.status && viewMode === 'graphTemp') {
        names.push(sensorInfo.Temperature.displaySensorName);
        keyNames.push('Temperature');
      } else if (sensorInfo?.Humidity.status && sensorInfo?.Humidity.status && viewMode === 'graphHum') {
        names.push(sensorInfo.Humidity.displaySensorName);
        keyNames.push('Humidity');
      }

      workerRefLineChart.current.postMessage({
        data: data,
        names,
        keyNames,
        colors,
      })
    }
  }, [data, viewMode])


  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags });
    }
    fetchData();
  }, [deviceState]);
  return (
    <>
      {loading && <Loader isBarCircle={loading} />}
      <div className="p-4 space-y-2">
        <div className="bg-white min-h-10 w-full md:w-[50%]  p-1 rounded-lg shadow-md">
          <div className="flex flex-col ml-2 items-start w-full mt-1">
            <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-[50%]">
              <span className="font-bold">Device ID:</span>{' '}
              {deviceState.imeiNumber}
            </div>
            <div className="flex items-center md:ml-0 md:w-1/2">
              <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string} />
              <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
                Last data received at: {dashBoardSts?.timeStamp}
              </p>
            </div>
          </div>
        </div>
        <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 md:grid-cols-3  gap-4">
          {sensorInfo?.['Temperature']?.status && (
            <SensorCard
              sensor={sensorInfo.Temperature.status ? sensorInfo.Temperature.displaySensorName : "Temperature"}
              value={dashBoardSts ? dashBoardSts.Temperature : "N/A"}
              unit={sensorInfo.Temperature.status ? sensorInfo.Temperature.displaySensorUnit : "°C"}
            />
          )}
          {sensorInfo?.['Humidity']?.status && (
            <SensorCard
              sensor={sensorInfo.Humidity.status ? sensorInfo.Humidity.displaySensorName : "Humidity"}
              value={dashBoardSts ? dashBoardSts.Humidity : "N/A"}
              unit={sensorInfo.Humidity.status ? sensorInfo.Humidity.displaySensorUnit : "%"}
            />
          )}

        </div>

        <div className='bg-white p-4'>
          <div className="relative">
            <div className="absolute top-0 right-0">
              {/* <LongMenu
              options={["Download as CSV", "Download as PDF"]}
              onOptionClick={handleExportBarChart}
              id={1}
            /> */}
            </div>
          </div>
          <p>
            {viewMode === 'graphTemp' ? "Flow" : "Totalizer"} data variation with time (between{" "}
            {data.length > 0
              ? data[data.length - 1].timeStamp +
              " to " +
              data[0].timeStamp
              : " "}
            )
          </p>
          <div className="button-group my-4">
            <button
              onClick={() => handleButtonClick('graphTemp')}
              className={`px-4 py-1 mr-2  rounded-lg  ${viewMode === 'graphTemp' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              Temperature
            </button>
            <button
              onClick={() => handleButtonClick('graphHum')}
              className={`px-4 py-1  rounded-lg ${viewMode === 'graphHum' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              Humidity
            </button>
          </div>
          <LineChart
            categories={tempHumSingleLineChart.categories}
            series={tempHumSingleLineChart.seriesData}
            yAxisTitle="Data"
            xAxisTitle="Time"
            height={450}
          />
        </div>
      </div>

    </>
  )
}

export default DashboardTempHumSingle