// LineChart.tsx
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface SeriesData {
  name: string;
  data: (string | number)[];
  color?: string;
}

interface LineChartProps {
  title?: string;
  categories: string[];
  series: SeriesData[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
  tooltipUnit?: string;
}

const LineChart: React.FC<LineChartProps> = ({ title, categories, series,height, yAxisTitle , xAxisTitle, tooltipUnit}) => {

  const formattedSeries = series.map((serie) => ({
    ...serie,
    data: serie.data.map((value) => {
      return typeof value === 'string' ? Number(value) : value;
    }),
  }));
  
  
  const options = {
    chart: {
      type: 'line',
      height: height,
      zoomType: 'x'
    },
    title: {
      text: title,
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      title: {
        text: xAxisTitle ? xAxisTitle : 'Categories',
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle ? yAxisTitle : 'Values',
      },
    },
    series: formattedSeries,
    credits: {
      enabled: false, // Disable the watermark
    },
    tooltip: {
      shared: true,  // This enables the tooltip to display information for all series at the same x point
      crosshairs: true,  // Enable crosshairs to show on hover
      formatter: function(this: Highcharts.TooltipFormatterContextObject){
        const points = this.points?.map(point => {
          return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} ${tooltipUnit}</b><br/>`;
        }).join('');
        return `<b>${this.x}</b><br/>${points}`;
      }
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} constructionType={"stockChart"}/>;
};

export default LineChart;


// const categories = ['Devices', 'Users', 'Organization'];
//   const seriesData = [
//     {
//       name: 'Paid',
//       data: [3, 2, 5],
//       color: '#117DAE', // Custom color for 2023 Sales
//     },
//     {
//       name: 'Outstanding',
//       data: [4, 3, 6],
//       color: '#A4A4A6', // Custom color for 2024 Sales
//     },
//   ];
//   <LineChart title="Overview" categories={categories} series={seriesData} height={250} />