import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { WATER_METER_TEMPLATE, WATER_TOTALIZER_FLOW, ENERGY_1, AC_MONITORING, WATER_TOTALIZER_JOIN, TEMPERATURE_HUMIDITY_SINGLE, LEVEL_MONITORING_420, TEMPERATURE_HUMADITY_BOTH, LEVEL_MONITORING, WATER_QUALITY_1, WATER_QUALITY_2, WATER_QUALITY_3, WATER_QUALITY_4, WATER_QUALITY_5, WATER_QUALITY_6 } from '../../utils/config-templateId/TemplateId';
import DashboardWaterMeter from './water-meter/DashboardWaterMeter';
import DashboardWaterTotalizerFlow from './Water-Totalizer_Flow/DashboardWaterTotalizerFlow';
import DashboardEnergyMonitoring from './Energy-Monitoring/DashboardEnergyMonitoring';
import DashboardACMonitoring from './AC-Controller/DashboardACMonitoring';
import DashboardWaterTotalizerJoin from './Water-Totalizer_Join/DashboardWaterTotalizerJoin';
import DashboardTempHumSingle from './Temperature-Humidity/DashboardTempHumSingle';
import Dashboard420MassLevel from './4-20MassLevel/Dashboard420MassLevel';
import DashboardTempHumDouble from './Temperature-Humidity/DashboardTempHumDouble';
import DashboardLevelMonitoring from './Level-Monitoring/DashboardLevelMonitoring';
import DashboardWaterQualityCommon from './Water-Quality/DashboardWaterQualityCommon';

const TemplateDashboardRouting = () => {
  // const { _id } = useParams()
  const location = useLocation();
  const state = location.state;
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  // console.log("Selected Template", selectedTemplate);
  

  useEffect(() => {
    setSelectedTemplate(state.templateId);
  }, [state]);
  return (
    <div>
      {selectedTemplate === WATER_METER_TEMPLATE && (
        <>
        <p>1</p>
        <DashboardWaterMeter deviceState={state} />
        </>
      )}
      {selectedTemplate === WATER_TOTALIZER_FLOW && (
        <>
        <p>2</p>
        <DashboardWaterTotalizerFlow deviceState={state} />
        </>
      )}
      {selectedTemplate === ENERGY_1 && (
        <>
        <p>3</p>
        <DashboardEnergyMonitoring deviceState={state} />
        </>
      )}
      {selectedTemplate === AC_MONITORING && (
        <>
        <p>4</p>
        <DashboardACMonitoring deviceState={state} />
        </>
      )}
      {selectedTemplate === WATER_TOTALIZER_JOIN && (
        <>
        <p>5</p>
        <DashboardWaterTotalizerJoin deviceState={state} />
        </>
      )}
      {selectedTemplate === TEMPERATURE_HUMIDITY_SINGLE && (
        <>
        <p>6</p>
        <DashboardTempHumSingle deviceState={state} />
        </>
      )}
      {selectedTemplate === TEMPERATURE_HUMADITY_BOTH && (
        <>
        <p>7</p>
        <DashboardTempHumDouble deviceState={state} />
        </>
      )}
      {selectedTemplate === LEVEL_MONITORING_420 && (
        <>
        <p>8</p>       
        <Dashboard420MassLevel deviceState={state} />
        </>
      )}
      {selectedTemplate === LEVEL_MONITORING && (
        <>
        <p>9</p>
        <DashboardLevelMonitoring deviceState={state} />
        </>
      )}
      {selectedTemplate === WATER_QUALITY_1 ||selectedTemplate === WATER_QUALITY_2 ||selectedTemplate === WATER_QUALITY_3 ||selectedTemplate === WATER_QUALITY_4 ||selectedTemplate === WATER_QUALITY_5 ||selectedTemplate === WATER_QUALITY_6 && (
        <>
        <p>10</p>
        <DashboardWaterQualityCommon deviceState={state} />
        </>
      )}


    </div>
  );
};

export default TemplateDashboardRouting;
