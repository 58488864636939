import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useTemplateState } from '../../app-context/template-context/TemplateState'
import { setTemplate } from '../../app-context/template-context/TemplateAction'
import SearchAndActions from '../../components/search-action/SearchAndActions';
import Modal from '../../components/modal/Modal';
import Loader from '../../components/loader/Loader';
import { usePermissionState } from '../../app-context/permission-context/PermissionState';
const TemplateTable = lazy(() => import('./components/TemplateTable'));
const LazyAddNewTemplateForm = lazy(() => import('./components/AddNewTemplateForm'))


const Template = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.template?.canCreate || false
  const { dispatch, template,loading } = useTemplateState()
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [addTemplateModal, setAddTemplateModal] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  

  useEffect(() => {
    if (template.template.length === 0) {
      setTemplate(dispatch, {
        page: 1,
        limit: 10,
      })
    }
  }, [])
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout((): void => {
      setTemplate(dispatch, {
        page: 1,
        limit: 10,
        search: event.target.value,
      })
  }, 500);
  };

  const handleAddNew = () => {
    setAddTemplateModal(true);
  };

  const handleExport = () => {
    // Logic for exporting data
  };

  return (
    <div className="p-1 py-2">
      {loading &&   <Loader isBarCircle={loading}/>}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <TemplateTable />
        </Suspense>
      </div>


      <Modal
        // sx={customSx}
        toggleModal={addTemplateModal}
        onClose={() => {
          setAddTemplateModal(false)
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddNewTemplateForm
            onClose={() => {
              setAddTemplateModal(false)
            }}
            action='ADD_NEW'
          />
        </Suspense>
      </Modal>

    </div>
  )
}

export default Template