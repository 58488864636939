import React, { useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import SensorCard from '../../../components/card/SensorCard';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import { ConsumptionItem } from '../../../workers/consumption-data-worker/consumptionWorker';
import BarChart from '../../../components/chart/bar-chart/BarChart';

interface DashboardWaterTotalizerFlowProps {
  deviceState: Device;
}
interface SensorData {
  [key: string]: string | number;
}

interface ResponseData {
  deviceData: SensorData[];
}

interface SensorInfo {
  [key: string]: {
    displaySensorName: string;
    displaySensorUnit: string;
    status: boolean;
  };
}

const DashboardWaterTotalizerFlow: React.FC<DashboardWaterTotalizerFlowProps> = ({
  deviceState,
}) => {

  const [data, setData] = useState<SensorData[]>([]);
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const workerRefSensor = useRef<Worker | null>(null);
  const workerRefLineChart = useRef<Worker | null>(null);
  const workerRefConProcessor = useRef<Worker | null>(null);
  const workerRefConsumption = useRef<Worker | null>(null);
  const [dashBoardSts, setDashboardSts] = useState<SensorData>();
  const [consumptionData, setConsumtptionData] = useState<ConsumptionItem[]>();
  const [consumptioBarChart, setConsumptionBarChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });

  const [totalizerFlowLineChart, setTotalizerFlowLineChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });

  const [viewMode, setViewMode] = useState<'Flowrate' | 'Totalizer'>('Flowrate');
  const handleButtonClick = (mode: 'Flowrate' | 'Totalizer') => {
    setViewMode(mode);
  };

  const fetchData = async () => {
    try {
      const response = await http(
        `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
      );
      const dataAsSensor = response as ResponseData;
      setData(dataAsSensor.deviceData);
      setDashboardSts(dataAsSensor.deviceData[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  console.log("consuption", consumptioBarChart);


  useEffect(() => {
    workerRefSensor.current = new Worker(
      new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
    );

    workerRefSensor.current.onmessage = function (e) {
      const { sensorInfo } = e.data;
      setSensorInfo(sensorInfo);
    };

    workerRefLineChart.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefLineChart.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setTotalizerFlowLineChart({ seriesData, categories })
    };

    workerRefConsumption.current = new Worker(
      new URL(
        '../../../workers/consumption-data-worker/consumptionWorker.ts',
        import.meta.url
      )
    );
    workerRefConsumption.current.onmessage = function (e) {
      const consumptionData = e.data as { success: boolean; data: [] }; // Receive processed consumption data
      setConsumtptionData(consumptionData.data);
    };

    workerRefConProcessor.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefConProcessor.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setConsumptionBarChart({ categories, seriesData });
    };

    return () => {
      if (workerRefSensor.current) {
        workerRefSensor.current.terminate();
      }

      if (workerRefLineChart.current) {
        workerRefLineChart.current.terminate();
      }

      if (workerRefConsumption.current) {
        workerRefConsumption.current.terminate();
      }
    };
  }, []);

  useEffect(() => {
    if (workerRefLineChart.current && data.length > 0) {
      const names: string[] = [];
      const keyNames: string[] = [];
      const colors = ['117DAE', 'DD0000'];

      if (sensorInfo?.Flow.status) {
        names.push(sensorInfo.Flow.displaySensorName);
        keyNames.push('Flow');
      }

      workerRefLineChart.current.postMessage({
        data: data,
        names,
        keyNames,
        colors,
      })
    }

    if (workerRefConsumption.current && data.length > 0) {
      workerRefConsumption.current.postMessage({
        deviceData: data,
        tagName: ['Totalizer'],
      });
    }
  }, [data, viewMode])

  useEffect(() => {
    if (workerRefConProcessor.current && consumptionData) {
      const names: string[] = [];
      if (sensorInfo?.Totalizer.status) {
        names.push(sensorInfo.Totalizer.displaySensorName);
      }
      const keyNames = ['consumptionTotalizer'];
      const colors = ['2980b9'];
      workerRefConProcessor.current.postMessage({
        data: consumptionData.reverse(),
        names,
        keyNames,
        colors,
        timeKey: 'hour',
      });
    }
  }, [consumptionData]);

  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags });
    }
    fetchData();
  }, [deviceState]);

  return (
    <div className="p-4 space-y-2">
      <div className="bg-white min-h-10 w-full md:w-[50%]  p-1 rounded-lg shadow-md">
        <div className="flex flex-col items-start ml-2 w-full mt-1">
          <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-full">
            <span className="font-bold">Device ID:</span>{' '}
            {deviceState.imeiNumber}
          </div>
          <div className="flex items-center md:ml-0 md:w-1/2">
            <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string} />
            <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
              Last data received at: {dashBoardSts?.timeStamp}
            </p>
          </div>
        </div>
      </div>

      <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 md:grid-cols-3  gap-4">
        {sensorInfo?.['Flow']?.status && (
          <SensorCard
            sensor={sensorInfo.Flow.status ? sensorInfo.Flow.displaySensorName : "Flow"}
            value={dashBoardSts ? dashBoardSts.Flow : "N/A"}
            unit={sensorInfo.Flow.status ? sensorInfo.Flow.displaySensorUnit : "m³"}
          />
        )}
        {sensorInfo?.['Totalizer']?.status && (
          <SensorCard
            sensor={sensorInfo.Totalizer.status ? sensorInfo.Totalizer.displaySensorName : "Totalizer"}
            value={dashBoardSts ? dashBoardSts.Totalizer : "N/A"}
            unit={sensorInfo.Totalizer.status ? sensorInfo.Totalizer.displaySensorUnit : "m³"}
          />
        )}
      </div>

      {/* this  is for chart */}
      {/* Line Chart with Dynamic Buttons */}
      <div className='bg-white p-4'>
        <div className="relative">
          <div className="absolute top-0 right-0">
            {/* <LongMenu
              options={["Download as CSV", "Download as PDF"]}
              onOptionClick={handleExportBarChart}
              id={1}
            /> */}
          </div>
        </div>
        <p>
          {viewMode === 'Flowrate' ? "Flow" : "Totalizer"} data variation with time (between{" "}
          {data.length > 0
            ? data[data.length - 1].timeStamp +
            " to " +
            data[0].timeStamp
            : " "}
          )
        </p>
        <div className="button-group my-4">
          <button
            onClick={() => handleButtonClick('Flowrate')}
            className={`px-4 py-1 mr-2  rounded-lg  ${viewMode === 'Flowrate' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          >
            Flow
          </button>
          <button
            onClick={() => handleButtonClick('Totalizer')}
            className={`px-4 py-1  rounded-lg ${viewMode === 'Totalizer' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          >
            Totalizer
          </button>
        </div>
        {viewMode === 'Flowrate' ? (
          <LineChart
            categories={totalizerFlowLineChart.categories}
            series={totalizerFlowLineChart.seriesData}
            yAxisTitle="Data"
            xAxisTitle="Time"
            height={450}
          />
        ) : (
          <BarChart
            categories={consumptioBarChart.categories}
            series={consumptioBarChart.seriesData}
            height={450}
            xAxisTitle="Time"
            yAxisTitle="Consumption Summary"
          />
        )}
      </div>
    </div>
  )
}

export default DashboardWaterTotalizerFlow;