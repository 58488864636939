// COMPLETED
const WATER_METER_TEMPLATE = '66bf197b6d4b2e66178ef9dc' // DONE
const TEMPERATURE_HUMADITY_BOTH = '66c3134b6d4b2e66178efe53' //Done
const TEMPERATURE_HUMIDITY_SINGLE = '66c2d6a76d4b2e66178efb2e'// Done
const WATER_TOTALIZER_FLOW = '66c30c386d4b2e66178efdd6' //Done
const TOTALIZER_JOIN = '66c323666d4b2e66178f002f' //Done
const WATER_TOTALIZER_JOIN = '66c31c096d4b2e66178efe7b' //Done
const AC_MONITORING = '67209012f028abf088e0d520' //Done
const LEVEL_MONITORING_420 = '66c326f56d4b2e66178f00c8' // Done
const LEVEL_MONITORING = '66c322bf6d4b2e66178f0020' //Done
const MA_420 = '66c324c66d4b2e66178f0060'
const PULSE_WATER_TEMPLATE = '66c327f26d4b2e66178f00e1'



const WATER_QUALITY_1 = '66bf218e6d4b2e66178efa86'
const WATER_QUALITY_2 = '66c2d7606d4b2e66178efb44'
const WATER_QUALITY_3 = '66c2d8326d4b2e66178efb82'
const WATER_QUALITY_4 = '66c30cfd6d4b2e66178efdea'
const WATER_QUALITY_5 = '66c312676d4b2e66178efe18'
const WATER_QUALITY_6 = '66c312cc6d4b2e66178efe32'




const WATER_TOTALIZER_FLOW_BATTERY = '66c325676d4b2e66178f008e'
const PIEZO_TEMPLATE = '66bf1dbe6d4b2e66178efa44'
const ENERGY_1 = '66c2dad96d4b2e66178efc1a'
const DG = '66c31e486d4b2e66178efeba'
const SOLAR = '66c3206d6d4b2e66178eff4f'


const TEMPERATURE_MONITORING = '667a894a024eede10f3499db'
const VIBRATION_TEMPLATE_1 = '667a8b33024eede10f349a6c'
const VIBRATION_TEMPLATE_2 = '667a8b6b024eede10f349a8d'
const AUTOMATION = '667a9022024eede10f349ad9'
const TOTALIZER_FLOWPUMP = '667a94c2024eede10f349bbc'
const ATLANTA_WATER = '667a9bc3024eede10f349c84'


// 
// const TOTALIZER_WATER_TEMPLATE = '66c2d58f6d4b2e66178efb17'
// const TEMPERATURE_SINGLE = '66c3216d6d4b2e66178efff7'
// const MA420='66c324c66d4b2e66178f0060'
// const CGWA = '66c325ca6d4b2e66178f00a6'
// const PULSE_WATER_TEMPLATE = '66c327f26d4b2e66178f00e1'




export {
  WATER_METER_TEMPLATE,
  TEMPERATURE_HUMADITY_BOTH,
  TEMPERATURE_HUMIDITY_SINGLE,
  WATER_QUALITY_1,
  WATER_QUALITY_2,
  WATER_QUALITY_3,
  WATER_QUALITY_4,
  WATER_QUALITY_5,
  WATER_QUALITY_6,
  LEVEL_MONITORING,
  ENERGY_1,
  TEMPERATURE_MONITORING,
  DG,
  VIBRATION_TEMPLATE_1,
  VIBRATION_TEMPLATE_2,
  AUTOMATION,
  WATER_TOTALIZER_FLOW,
  TOTALIZER_FLOWPUMP,
  ATLANTA_WATER,
  TOTALIZER_JOIN,
  SOLAR,
  PIEZO_TEMPLATE,
  WATER_TOTALIZER_JOIN,
  WATER_TOTALIZER_FLOW_BATTERY,
  AC_MONITORING,
  LEVEL_MONITORING_420,
  MA_420,
  PULSE_WATER_TEMPLATE
}
