import React, { useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import Loader from '../../../components/loader/Loader';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import { SensorInfo } from '../../../workers/device-data-worker/dataWorker';
import Card from '../../../components/card/Card';
import LineChart from '../../../components/chart/line-chart/LineChart';

interface DashboardLevelMonitoringProps {
    deviceState: Device;
}

interface SensorData {
    [key: string]: string | number;
}

interface ResponseData {
    deviceData: SensorData[];
}

const DashboardLevelMonitoring: React.FC<DashboardLevelMonitoringProps> = ({
    deviceState,
}) => {
    const [data, setData] = useState<SensorData[]>([]);
    const [dashBoardSts, setDashboardSts] = useState<SensorData>();
    const chartRef = useRef<HTMLDivElement>(null);
    const workerRefSensor = useRef<Worker | null>(null);
    const workerRefLineChart = useRef<Worker | null>(null);
    const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
    const [loading, setLoading] = useState(false);
    const [massLevelLineChart, setMassLevelLineChart] = useState<{
        seriesData: {
            name: string;
            data: number[];
            color: string;
        }[];
        categories: string[];
    }>({
        categories: [],
        seriesData: [],
    });

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await http(
                `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
            );
            const dataAsSensor = response as ResponseData;
            setData(dataAsSensor.deviceData);
            setDashboardSts(dataAsSensor.deviceData[0]);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        } finally {
            setLoading(false);

        }
    };

    console.log('Sensor Information', sensorInfo)

    useEffect(() => {
        workerRefSensor.current = new Worker(
            new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
        );
        workerRefSensor.current.onmessage = function (e) {
            const { sensorInfo } = e.data;
            setSensorInfo(sensorInfo);

        };

        workerRefLineChart.current = new Worker(
            new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );
        workerRefLineChart.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setMassLevelLineChart({ seriesData, categories })
        };
    }, []);

    useEffect(() => {
        if (workerRefLineChart.current && data.length > 0) {
            const names: string[] = [];
            const keyNames: string[] = [];
            const colors = ['117DAE'];

            if (sensorInfo?.Level.status) {
                names.push(sensorInfo.Level.displaySensorName);
                keyNames.push('Level');
            }
            workerRefLineChart.current.postMessage({
                data: data,
                names,
                keyNames,
                colors,
            })
        }
    }, [data])



    useEffect(() => {
        if (deviceState.tags && workerRefSensor.current) {
            workerRefSensor.current.postMessage({ tags: deviceState.tags });
        }
        fetchData();
    }, [deviceState]);

    return (
        <>
            {loading && <Loader isBarCircle={loading} />}
            <div className="bg-white min-h-10 w-full md:w-[50%]  p-1 rounded-lg shadow-md">
                <div className="flex flex-col ml-2 items-start w-full mt-1">
                    <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-[50%]">
                        <span className="font-bold">Device ID:</span>{' '}
                        {deviceState.imeiNumber}
                    </div>
                    <div className="flex items-center md:ml-0 md:w-1/2">
                        <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string} />
                        <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
                            Last data received at: {dashBoardSts?.timeStamp}
                        </p>
                    </div>
                </div>
            </div>
            <div className="border border-gray-400 rounded-md p-2 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2">
                {sensorInfo?.['Level']?.status && (
                    <Card
                        heading={sensorInfo.Level.status ? sensorInfo.Level.displaySensorName : "Level"}
                        value={`${dashBoardSts ? dashBoardSts.Level : "N/A"} ${sensorInfo.Level.status ? sensorInfo.Level.displaySensorUnit : "cm"}`}
                    />
                )}
            </div>

            <div className="border border-gray-400 p-2 rounded-md">
                <div className="relative">
                    <div className="absolute top-0 right-0">
                        {/* <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            /> */}
                    </div>
                    <p>
                        Level data variation with time (Between{" "}
                        {data.length > 0
                            ? data[data.length - 1].timestamp +
                            " to " +
                            data[0].timestamp
                            : " "}
                        )
                    </p>
                </div>
                <div ref={chartRef} style={{ marginTop: "15px" }}>
                    <LineChart
                        categories={massLevelLineChart.categories || []}
                        series={massLevelLineChart.seriesData || []}
                        height={450}
                        yAxisTitle="Data"
                    />
                </div>
            </div>

        </>
    )
}

export default DashboardLevelMonitoring