import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { http } from '../../utils/helper/http';
import { baseUrlDevice } from '../../utils/env/env.config';
import DataTable from '../../components/table/DataTable';
import { Checkbox, IconButton } from '@mui/material'
import Loader from '../../components/loader/Loader';
import LongMenu from '../../components/long-menu/LongMenu';
import * as XLSX from 'xlsx'
import { DataRow } from '../../types/table';
import generatePdf from '../../utils/generate-pdf/generatePdf';
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
import AlertDelete from '../../components/alert-delete/AlertDelete';
import DeleteIcon from '@mui/icons-material/Delete'
import { RoleBack } from '../../types/Role';
import RoleBasedComponent from '../../utils/protected-route-component/RoleBasedComponent';
interface SensorData {
  [key: string]: string | number;
}

interface ResponseData {
  deviceData: SensorData[];
}

interface SensorInfo {
  [key: string]: { displaySensorName: string; displaySensorUnit: string, status: boolean };
}

type TransformedLogEntry<T extends SensorInfo> = {
  logId: string;
  rssi: string;
  timeStamp: string;
  checkbox?: React.ReactNode
} & {
  [K in keyof T as T[K]['status'] extends true ? K : never]?: number; // Include only if status is true
};

const DeviceData = () => {
  const { _id } = useParams()
  const location = useLocation();
  const { state: deviceState } = location;
  const [columns, setColumns] = useState<{ field: string; headerName: string }[]>([]);
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const [data, setData] = useState<TransformedLogEntry<SensorInfo>[]>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<{
    startDate: string
    endDate: string
  }>({
    startDate: '',
    endDate: '',
  })
  const [selected, setSelected] = useState<string[]>([])
  const workerRefDeviceData = useRef<Worker | null>(null);
  const workerRefSensor = useRef<Worker | null>(null);
  // const workerRefConsumption = useRef<Worker | null>(null);
  const { currentUser } = useCurrentUserState()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    workerRefSensor.current = new Worker(new URL('../../workers/sensor-worker/sensorWorker.ts', import.meta.url));
    workerRefDeviceData.current = new Worker(new URL('../../workers/device-data-worker/dataWorker.ts', import.meta.url));
    // workerRefConsumption.current = new Worker(new URL('../../workers/consumption-data-worker/consumptionWorker.ts', import.meta.url));

    // Handle messages from the sensor worker
    workerRefSensor.current.onmessage = function (e) {
      const { columns: initialColumns, sensorInfo } = e.data;
      setSensorInfo(sensorInfo);
      console.log(initialColumns, sensorInfo);
      setColumns([{ field: "timeStamp", headerName: "TimeStamp" }, ...initialColumns]);
    };

    console.log('print current user:- ', currentUser);
    // workerRefConsumption.current.onmessage = function (e) {
    //   const consumptionData = e.data; // Receive processed consumption data
    //   console.log(consumptionData);

    // };

    // Clean up the workers on component unmount
    return () => {
      if (workerRefSensor.current) {
        workerRefSensor.current.terminate();
      }
      if (workerRefDeviceData.current) {
        workerRefDeviceData.current.terminate();
      }
      // if (workerRefConsumption.current) {
      //   workerRefConsumption.current?.terminate();
      // }
    };
  }, []);
  const getDataOnSelectedDate = async () => {
    try {
      setLoading(true);
      const response = await http(
        baseUrlDevice +
        'devices-data/influxData?deviceId=' +
        _id +
        '&start=' +
        dateRange.startDate +
        '&end=' +
        dateRange.endDate,
      ) as ResponseData
      if (workerRefDeviceData.current && sensorInfo) {
        workerRefDeviceData.current.postMessage({ deviceData: response.deviceData, sensorInfo, deviceState });

        workerRefDeviceData.current.onmessage = function (e) {
          const transformedData = e.data;
          setData(transformedData);
        };
      }
      // if (workerRefConsumption.current) {
      //   workerRefConsumption.current.postMessage({
      //     deviceData: response.deviceData,
      //     tagName: ["Forward Total Flow"],
      //   });
      // }
      setLoading(false);
    } catch (err) {
      console.log("ERROR OCCURED WHILE FETCHING DEVICE DATA");
      setLoading(false);
    }
  }

  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags, deviceState: deviceState});
    }
  }, [deviceState]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      getDataOnSelectedDate()
    }
  }, [dateRange.startDate, dateRange.endDate, _id])

  const handleClickAll = () => {
    if (selected.length > 0) {
      setSelected([])
    } else {
      setSelected(data.map((row) => row.logId))
    }
  }
  const handleClickSingle = (id: string) => {
    const index = selected.findIndex((item) => item === id)
    setSelected((prevSelected: string[]) =>
      index === -1
        ? [...prevSelected, id]
        : prevSelected.filter((item: string) => item !== id),
    )
  }

  const dataWithCheckBox = data.map((row) => ({
    ...row,
    checkbox: (
      <>
        <RoleBasedComponent roleName={[RoleBack.USER]}>
          <Checkbox
            color='primary'
            checked={selected.indexOf(row.logId) !== -1}
            onChange={() => handleClickSingle(row.logId)}
          />
        </RoleBasedComponent>
      </>
    ),
  }))
  const columnWithCheckBox: { field: string; headerName: string | React.ReactNode }[] = [
    {
      field: 'checkbox',
      headerName: (
        <>
          <RoleBasedComponent roleName={[RoleBack.USER]}>
            <IconButton onClick={handleClickAll}>
              <Checkbox checked={selected.length > 0} />
            </IconButton>
          </RoleBasedComponent>
        </>
      )
    }, ...columns
  ]

  function handleMenuOptionClick(
    option: string,
    // id?: string | number,
    // state?: DataRow,
  ) {

    if (data.length === 0) {
      return
    }

    switch (option) {
      case 'Download as CSV': {
        const headers = columns.map(col => col.headerName);
        const d = data as DataRow[]
        const formattedData = d.map(item => {
          const row: { [key: string]: string | number } = {}; // Use specific types for row values
          columns.forEach(column => {
            row[column.headerName] = item[column.field] as string; // Directly access the value with type-safe key
          });
          return row;
        });
        const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        XLSX.writeFile(workbook, 'data.xlsx');
        break;
      }

      case 'Download as PDF': {
        // Handle PDF download here
        const headers = columns.map(col => col.headerName);
        const d = data as DataRow[]
        const formattedData = d.map(item => {
          const row: { [key: string]: string | number } = {}; // Use specific types for row values
          columns.forEach(column => {
            row[column.headerName] = item[column.field] as string; // Directly access the value with type-safe key
          });
          return row;
        });
        generatePdf(headers, formattedData, currentUser.appLogo, currentUser.appName)
        break;
      }

      default:
        console.log("Unknown option selected");
    }
  }
  const handleConfirmDelete = async () => {
    try {

      await http(baseUrlDevice + 'devices-data', 'DELETE', selected)
      getDataOnSelectedDate()
      setSelected([])
      // toast.success('Data deleted successfully')
    } catch (error) {
      console.log(error)
    }
    setDeleteModalOpen(false)
  }

  return (
    <>
      {loading && <Loader isBarCircle={loading} />}
      <AlertDelete
        isModalOpen={isDeleteModalOpen}
        handleCloseModal={() => {
          setDeleteModalOpen(false)
        }}
        handleConfirmDelete={handleConfirmDelete}
      />

      <div className='flex flex-col sm:flex-row items-center pl-2'>
        <div className='flex-1 sm:mr-2 mb-2 sm:mb-0'>
          <h3 className='text-xl sm:text-2xl text-black mt-1'>Device Data</h3>
          <h2 className='text-sm'>Device name :{deviceState.deviceName}  </h2>
        </div>
        <div className='flex flex-col sm:flex-row items-center sm:p-2'>
          <RoleBasedComponent roleName={[RoleBack.USER]} >
            {selected.length > 0 && (
              <IconButton
                onClick={() => {
                  setDeleteModalOpen(true)
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </RoleBasedComponent>
          {/* <span className='space-x-2'>
            <FilterListIcon />
          </span> */}
          <LongMenu
            options={['Download as CSV', 'Download as PDF']}
            onOptionClick={handleMenuOptionClick}
            id={1}
          />
        </div>
      </div>

      <div className='flex flex-col md:flex-row gap-4 mb-2 ml-2'>
        <div className='flex flex-col'>
          <label htmlFor='start_date' className='text-gray-700'>
            Start Date:
          </label>
          <input
            type='date'
            id='startDate'
            value={dateRange.startDate}
            onChange={(event) => {
              const { id, value } = event.target
              setDateRange((prevDateRange) => ({
                ...prevDateRange,
                [id]: value,
              }))
            }}
            className='border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500'
          />
        </div>

        <div className='flex flex-col'>
          <label htmlFor='end_date' className='text-gray-700'>
            End Date:
          </label>
          <input
            type='date'
            id='endDate'
            value={dateRange.endDate}
            onChange={(event) => {
              const { id, value } = event.target
              setDateRange((prevDateRange) => ({
                ...prevDateRange,
                [id]: value,
              }))
            }}
            className='border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500'
          />
        </div>
      </div>
      <div
        className='flex flex-col text-center mt-8 md:mt-0 ml-2'
      >
        <div style={{ height: 400, width: '100%' }}>
          <DataTable rows={dataWithCheckBox} columns={columnWithCheckBox} rowsPerPageOptions={[5, 10, 50, 100, 500]}/>
        </div>
      </div>
    </>
  )
}

export default DeviceData

































// import React, { useEffect, useState } from 'react'
// import { useLocation, useParams } from 'react-router-dom';
// import { http } from '../../utils/helper/http';
// import { baseUrlDevice } from '../../utils/env/env.config';
// import DataTable from '../../components/table/DataTable';
// import { Checkbox, IconButton } from '@mui/material'

// interface SensorData {
//   [key: string]: string | number;
// }

// interface ResponseData {
//   deviceData: SensorData[];
// }

// interface SensorInfo {
//   [key: string]: { displaySensorName: string; displaySensorUnit: string, status: boolean };
// }

// interface Sensor {
//   tagName: string;
//   displaySensorName: string;
//   sensorUnit: string;
//   status: boolean; // Adjust based on your actual data structure
// }
// type TransformedLogEntry<T extends SensorInfo> = {
//   logId: string;
//   rssi: string;
//   timeStamp: string;
//   checkbox?: React.ReactNode
// } & {
//   [K in keyof T as T[K]['status'] extends true ? K : never]?: number; // Include only if status is true
// };

// interface InitialColumns {
//   columns: { field: string; headerName: string }[];
//   sensorInfo: SensorInfo;
// }

// const DeviceData = () => {
//   const { _id } = useParams()
//   const location = useLocation();
//   const { state } = location;
//   const [columns, setColumns] = useState<{ field: string; headerName: string }[]>([]);
//   const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
//   const [data, setData] = useState<TransformedLogEntry<SensorInfo>[]>([]);
//   const [dateRange, setDateRange] = useState<{
//     startDate: string
//     endDate: string
//   }>({
//     startDate: '',
//     endDate: '',
//   })
//   const [selected, setSelected] = useState<string[]>([])


//   const getDataOnSelectedDate = async () => {
//     const response = await http(
//       baseUrlDevice +
//       'devices-data/influxData?deviceId=' +
//       _id +
//       '&start=' +
//       dateRange.startDate +
//       '&end=' +
//       dateRange.endDate,
//     ) as ResponseData

//     const transformedData = response.deviceData.map((entry) => {
//       const newEntry: TransformedLogEntry<SensorInfo> = {
//         logId: entry.logId as string,
//         rssi: entry.rssi as string,
//         timeStamp: entry.timeStamp as string,
//       };

//       for (const key in sensorInfo) {
//         if (sensorInfo[key].status && typeof entry[key] !== 'object' && entry[key] !== null) {
//           newEntry[key as keyof TransformedLogEntry<SensorInfo>] = entry[key] as string; // Cast key to valid type
//         }
//       }

//       return newEntry;
//     });

//     setData(transformedData);

//   }

//   useEffect(() => {
//     const { columns: initialColumns, sensorInfo } = state.tags.reduce(
//       (acc: InitialColumns, sensor: Sensor) => {
//         if (sensor.status) {
//           const column = {
//             field: sensor.tagName,
//             headerName: sensor.displaySensorName,
//           };
//           acc.columns.push(column);
//         }

//         acc.sensorInfo[sensor.tagName] = {
//           displaySensorName: sensor.displaySensorName,
//           displaySensorUnit: sensor.sensorUnit,
//           status: sensor.status,
//         };

//         return acc; // Ensure you return the accumulator
//       },
//       {
//         columns: [],
//         sensorInfo: {}
//       } as InitialColumns // Cast the initial value to InitialColumns
//     );
//     setSensorInfo(sensorInfo)
//     console.log(initialColumns, sensorInfo);
//     setColumns([{ field: "timeStamp", headerName: "TimeStamp" }, ...initialColumns]);


//     // Use initialColumns and sensorInfo as needed
//   }, [state]); // Add dependencies as necessary



//   useEffect(() => {
//     if (dateRange.startDate && dateRange.endDate) {
//       getDataOnSelectedDate()
//     }
//   }, [dateRange.startDate, dateRange.endDate, _id])

//   const handleClickAll = () => {
//     if (selected.length > 0) {
//       setSelected([])
//     } else {
//       setSelected(data.map((row) => row.logId))
//     }
//   }
//   const handleClickSingle = (id: string) => {
//     const index = selected.findIndex((item) => item === id)
//     setSelected((prevSelected: string[]) =>
//       index === -1
//         ? [...prevSelected, id]
//         : prevSelected.filter((item: string) => item !== id),
//     )
//   }

//   const dataWithCheckBox = data.map((row) => ({
//     ...row,
//     checkbox: (
//       <Checkbox
//         color='primary'
//         checked={selected.indexOf(row.logId) !== -1}
//         onChange={() => handleClickSingle(row.logId)}
//       />
//     ),
//   }))
//   const columnWithCheckBox: { field: string; headerName: string | React.ReactNode }[] = [
//     {
//       field: 'checkbox',
//       headerName: (
//         <>
//           <IconButton onClick={handleClickAll}>
//             <Checkbox checked={selected.length > 0} />
//           </IconButton>
//         </>
//       )
//     }, ...columns
//   ]

//   return (
//     <>

//       <div className='flex flex-col sm:flex-row items-center pl-2'>
//         <div className='flex-1 sm:mr-2 mb-2 sm:mb-0'>
//           <h3 className='text-xl sm:text-2xl text-black mt-1'>Device Data</h3>
//           <h2 className='text-sm'>Device name :{state.deviceName}  </h2>
//         </div>
//         <div className='flex flex-col sm:flex-row items-center sm:p-2'>
//           {/* {selected.length > 0 && (
//           <IconButton
//             onClick={() => {
//               setDeleteModalOpen(true)
//             }}
//           >
//             <DeleteIcon />
//           </IconButton>
//         )} */}
//           {/* <span className='space-x-2'>
//           <FilterListIcon />
//         </span>
//         <LongMenu
//           options={['Download as CSV', 'Download as PDF']}
//           onOptionClick={handleMenuOptionClick}
//           id={1}
//         /> */}
//         </div>
//       </div>

//       <div className='flex flex-col md:flex-row gap-4 mb-2'>
//         <div className='flex flex-col'>
//           <label htmlFor='start_date' className='text-gray-700'>
//             Start Date:
//           </label>
//           <input
//             type='date'
//             id='startDate'
//             value={dateRange.startDate}
//             onChange={(event) => {
//               const { id, value } = event.target
//               setDateRange((prevDateRange) => ({
//                 ...prevDateRange,
//                 [id]: value,
//               }))
//             }}
//             className='border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500'
//           />
//         </div>

//         <div className='flex flex-col'>
//           <label htmlFor='end_date' className='text-gray-700'>
//             End Date:
//           </label>
//           <input
//             type='date'
//             id='endDate'
//             value={dateRange.endDate}
//             onChange={(event) => {
//               const { id, value } = event.target
//               setDateRange((prevDateRange) => ({
//                 ...prevDateRange,
//                 [id]: value,
//               }))
//             }}
//             className='border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500'
//           />
//         </div>
//       </div>
//       <div
//         className='flex flex-col text-center mt-8 md:mt-0'
//         style={{ overflowX: 'auto' }}
//       >
//         <div style={{ height: 400, width: '100%' }}>
//           <DataTable rows={dataWithCheckBox} columns={columnWithCheckBox} />
//         </div>
//       </div>
//     </>
//   )
// }

// export default DeviceData

