import { lazy, Suspense, useEffect, useState } from "react"
import { useCurrentUserState } from "../../app-context/current-user-context/CurrentUserLoginState"
import { setRole } from "../../app-context/role-context/RoleAction"
import { useRoleState } from "../../app-context/role-context/RoleState"
import SearchAndActions from "../../components/search-action/SearchAndActions"
import Loader from "../../components/loader/Loader"
import Modal from "../../components/modal/Modal"
import { usePermissionState } from "../../app-context/permission-context/PermissionState"
const RoleTable = lazy(() => import('./components/RoleTable'));
const LazyAddNewRoleForm = lazy(() => import('./components/AddNewRoleForm'))

const Role = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.roleback?.canCreate || false
  const { currentUser } = useCurrentUserState()
  const { dispatch, loading, roles } = useRoleState()
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [addRoleModal, setAddRoleModal] = useState(false)

  useEffect(() => {
    if (currentUser.clientId && roles.length === 0) {
      setRole(dispatch, { page: 1, limit: 10, clientId: currentUser.clientId })
    }
  }, [currentUser.clientId])
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAddNew = () => {
    setAddRoleModal(true);
  };

  const handleExport = () => {
    // Logic for exporting data
  };

  return (
    <div className="p-1 py-2">
      {loading && <Loader isBarCircle={loading} />}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <RoleTable />
        </Suspense>
      </div>
      <Modal
        // sx={customSx}
        toggleModal={addRoleModal}
        onClose={() => {
          setAddRoleModal(false)
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddNewRoleForm
            onClose={() => {
              setAddRoleModal(false)
            }}
            action='ADD_NEW'
          />
        </Suspense>
      </Modal>
    </div>
  )
}

export default Role