import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useDeviceState } from '../../app-context/device-context/DeviceState'
import { setDevice } from '../../app-context/device-context/DeviceAction'
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState'
import { RoleBack } from '../../types/Role'
const DeviceTable = lazy(() => import('./components/DeviceTable'));
import SearchAndActions from '../../components/search-action/SearchAndActions'
import Modal from '../../components/modal/Modal'
import Loader from '../../components/loader/Loader'
import { usePermissionState } from '../../app-context/permission-context/PermissionState'
import downloadFile from '../../utils/csv-pdf-interceptor/download-file'
import { baseUrlDevice } from '../../utils/env/env.config'
const DeviceForm = lazy(() => import('./components/DeviceForm'))

const Devices = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.device?.canCreate || false
  const { device, dispatch, loading } = useDeviceState()
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { currentUser, loaded } = useCurrentUserState()
  const [addDeviceModal, setAddDeviceModal] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);


  const loadDeviceData = (search = '') => {
    setDevice(dispatch, {
      page: 1,
      limit: 10,
      search,
      organizationId: currentUser.organizationId || '',
      vendorId: currentUser.vendorId || '',
      userId: currentUser.role.name === RoleBack.USER ? currentUser._id : '',
      role: currentUser.role.name,
    });
  };


  useEffect(() => {
    if (device.device.length === 0 && loaded) {
      loadDeviceData()
    }
  }, [loaded])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout((): void => {
      loadDeviceData(event.target.value);
    }, 500);
  };

  const handleAddNew = () => {
    setAddDeviceModal(true);
  };

  const handleExport = async () => {
    const role = currentUser.role.name
    let queryParams = `devices/export-excel?`
    if (role === RoleBack.ORGANIZATIONADMIN && currentUser.organizationId) {
      queryParams += `organizationId=${currentUser.organizationId}&`;
    } else if (role === RoleBack.SITEADMIN) {
      queryParams += `vendorId=${currentUser.vendorId}&`;
    } else if (role === RoleBack.USER) {
      queryParams += `userId=${currentUser._id}&`;
    }
    if(searchTerm !== '' && searchTerm !== undefined){
      queryParams += `search=${searchTerm}`;
    }
    try {
      await downloadFile(
        baseUrlDevice + queryParams,
        (progress) => {
          setDownloadProgress(progress);
        },
        'GET',
        {},
        'device.xlsx'
      );
      setDownloadProgress(0);
    } catch (error) {
      setDownloadProgress(0);
    } finally{
      setDownloadProgress(0);

    }
  };


  return (
    <div className="p-1 py-2">
   {(loading || downloadProgress > 0) && <Loader isBarCircle={(loading || downloadProgress > 0)}/>}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <DeviceTable />
        </Suspense>
      </div>
      <Modal
        // sx={customSx}
        toggleModal={addDeviceModal}
        onClose={() => {
          setAddDeviceModal(false)
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <DeviceForm
            action='ADD_NEW'
            onClose={() => {
              setAddDeviceModal(false)
            }}
          />
        </Suspense>
      </Modal>
    </div>
  )
}

export default Devices