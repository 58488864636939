import React, { lazy, Suspense, useState } from 'react'
import SearchAndActions from '../../../components/search-action/SearchAndActions'
import { useAlertState } from '../../../app-context/alert-context/AlertState'
import Loader from '../../../components/loader/Loader'
import Modal from '../../../components/modal/Modal'
import { usePermissionState } from '../../../app-context/permission-context/PermissionState'
const ConsumptionAlertTable = lazy(() => import('./components/ConsumptionAertTable'));
const AddNewConsumptionAlert = lazy(() => import('./components/AddNewConsumptionAlert'))


const ConsumptionAlert = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.alert?.canCreate || false
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [addConsumptionAlertModal, setAddConsumptionAlertModal] = useState(false)
  const{loading}=useAlertState()

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAddNew = () => {
    setAddConsumptionAlertModal(true);
  };

  const handleExport = () => {
    // Logic for exporting data
  };

  return (
    <div className="p-1 py-2">
    {loading &&   <Loader isBarCircle={loading}  />}
    <SearchAndActions
      searchTerm={searchTerm}
      onSearchChange={handleSearchChange}
      onAddNew={handleAddNew}
      onExport={handleExport}
      handelAdd={canCreate}
    />
    <Modal
      // sx={customSx}
      toggleModal={addConsumptionAlertModal}
      onClose={() => {
        setAddConsumptionAlertModal(false)
      }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <AddNewConsumptionAlert
          onClose={() => {
            setAddConsumptionAlertModal(false)
          }}
          action='ADD_NEW'
        />
      </Suspense>
    </Modal>
    <Suspense fallback={<div>Loading...</div>}>
      <ConsumptionAlertTable />
    </Suspense>
  </div>
  )
}

export default ConsumptionAlert