import React, { useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import { SensorInfo } from '../../../workers/device-data-worker/dataWorker';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import Loader from '../../../components/loader/Loader';
import LineChart from '../../../components/chart/line-chart/LineChart';
import SensorCard from '../../../components/card/SensorCard';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';

interface DashboardWaterQualityProps {
  deviceState: Device;
}

interface SensorData {
  [key: string]: string | number;
}

interface ResponseData {
  deviceData: SensorData[];
}
const DashboardWaterQualityCommon: React.FC<DashboardWaterQualityProps> = ({
  deviceState,
}) => {
  const [data, setData] = useState<SensorData[]>([]);
  const [dashBoardSts, setDashboardSts] = useState<SensorData>();
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const workerRefSensor = useRef<Worker | null>(null);
  const workerRefLineChart = useRef<Worker | null>(null);
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState<string>('');
  const [waterQualityLineChart, setWaterQualityChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
      );
      const dataAsSensor = response as ResponseData;
      setData(dataAsSensor.deviceData);
      setDashboardSts(dataAsSensor.deviceData[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);

    }
  };

  useEffect(() => {
    workerRefSensor.current = new Worker(
      new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
    );
    workerRefSensor.current.onmessage = function (e) {
      const { sensorInfo } = e.data;
      const firstSensor = Object.keys(sensorInfo)[0];
      if (firstSensor) {
        switch (firstSensor) {
          case 'DO':
            setViewMode('graphDO')
            break;
          case 'CL':
            setViewMode('graphCL')
            break;
          case 'NI':
            setViewMode('graphNI')
            break;
          case 'PH':
            setViewMode('graphPH')
            break;
          case 'TDS':
            setViewMode('graphTDS')
            break;
          case 'TEMP':
            setViewMode('graphTEMP')
            break;
          case 'TURB':
            setViewMode('graphTURB')
            break;
          default:
            break;
        }
      }
      setSensorInfo(sensorInfo);
    };

    workerRefLineChart.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefLineChart.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setWaterQualityChart({ seriesData, categories })
    };
  }, []);


  const handleButtonClick = (mode: string) => {
    setViewMode(mode);
  };

  useEffect(() => {
    if (workerRefLineChart.current && data.length > 0) {
      const names: string[] = [];
      const keyNames: string[] = [];
      const colors: string[] = []

      if (sensorInfo?.DO.status && viewMode === 'graphDO') {
        names.push(sensorInfo.DO.displaySensorName);
        keyNames.push('DO');
        colors.push("FF0000");
      } else if (sensorInfo?.CL.status && viewMode === 'graphCL') {
        names.push(sensorInfo.CL.displaySensorName);
        keyNames.push('CL');
        colors.push("0000FF");
      } else if (sensorInfo?.NI.status && viewMode === 'graphNI') {
        names.push(sensorInfo.NI.displaySensorName);
        keyNames.push('NI');
        colors.push("008000");
      } else if (sensorInfo?.PH.status && viewMode === 'graphPH') {
        names.push(sensorInfo.PH.displaySensorName);
        keyNames.push('PH');
        colors.push("800080");
      } else if (sensorInfo?.TDS.status && viewMode === 'graphTDS') {
        names.push(sensorInfo.TDS.displaySensorName);
        keyNames.push('TDS');
        colors.push("008080");
      } else if (sensorInfo?.TEMP.status && viewMode === 'graphTEMP') {
        names.push(sensorInfo.TEMP.displaySensorName);
        keyNames.push('TEMP');
        colors.push("FFFF00");
      } else if (sensorInfo?.TURB.status && viewMode === 'graphTURB') {
        names.push(sensorInfo.TURB.displaySensorName);
        keyNames.push('TURB');
        colors.push('#000000');
      }

      workerRefLineChart.current.postMessage({
        data: data,
        names,
        keyNames,
        colors,
      })
    }
  }, [data, viewMode])

  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags });
    }
    fetchData();
  }, [deviceState]);
  return (
    <>
      {loading && <Loader isBarCircle={loading} />}
      <div className="p-4 space-y-2">
        <div className="bg-white min-h-10 w-full md:w-[50%]  p-1 rounded-lg shadow-md">
          <div className="flex flex-col ml-2 items-start w-full mt-1">
            <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-[50%]">
              <span className="font-bold">Device ID:</span>{' '}
              {deviceState.imeiNumber}
            </div>
            <div className="flex items-center md:ml-0 md:w-1/2">
              <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string} />
              <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
                Last data received at: {dashBoardSts?.timeStamp}
              </p>
            </div>
          </div>
        </div>

        <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 md:grid-cols-3  gap-4">
          {sensorInfo?.['DO']?.status && (
            <SensorCard
              sensor={sensorInfo.DO.status ? sensorInfo.DO.displaySensorName : "DO"}
              value={dashBoardSts ? dashBoardSts.DO : "N/A"}
              unit={sensorInfo.DO.status ? sensorInfo.DO.displaySensorUnit : "mg/L"}
            />
          )}
          {sensorInfo?.['CL']?.status && (
            <SensorCard
              sensor={sensorInfo.CL.status ? sensorInfo.CL.displaySensorName : "Chlorine"}
              value={dashBoardSts ? dashBoardSts.totalizer : "N/A"}
              unit={sensorInfo.CL.status ? sensorInfo.CL.displaySensorUnit : "m³"}
            />
          )}
          {sensorInfo?.['NI']?.status && (
            <SensorCard
              sensor={sensorInfo.NI.status ? sensorInfo.NI.displaySensorName : "Nitrates"}
              value={dashBoardSts ? dashBoardSts.NI : "N/A"}
              unit={sensorInfo.NI.status ? sensorInfo.NI.displaySensorUnit : "m³"}
            />
          )}
          {sensorInfo?.['PH']?.status && (
            <SensorCard
              sensor={sensorInfo.PH.status ? sensorInfo.PH.displaySensorName : "PH"}
              value={dashBoardSts ? dashBoardSts.PH : "N/A"}
              unit={sensorInfo.PH.status ? sensorInfo.PH.displaySensorUnit : "m³"}
            />
          )}
          {sensorInfo?.['TDS']?.status && (
            <SensorCard
              sensor={sensorInfo.TDS.status ? sensorInfo.TDS.displaySensorName : "TDS"}
              value={dashBoardSts ? dashBoardSts.TDS : "N/A"}
              unit={sensorInfo.TDS.status ? sensorInfo.TDS.displaySensorUnit : "ppm"}
            />
          )}
          {sensorInfo?.['TEMP']?.status && (
            <SensorCard
              sensor={sensorInfo.TEMP.status ? sensorInfo.TEMP.displaySensorName : "Temperature"}
              value={dashBoardSts ? dashBoardSts.TEMP : "N/A"}
              unit={sensorInfo.TEMP.status ? sensorInfo.TEMP.displaySensorUnit : "°C"}
            />
          )}
          {sensorInfo?.['TURB']?.status && (
            <SensorCard
              sensor={sensorInfo.TURB.status ? sensorInfo.TURB.displaySensorName : "Turbidity"}
              value={dashBoardSts ? dashBoardSts.TURB : "N/A"}
              unit={sensorInfo.TURB.status ? sensorInfo.TURB.displaySensorUnit : "m³"}
            />
          )}

        </div>

        {/* this  is for chart */}
        {/* Line Chart with Dynamic Buttons */}
        <div className='bg-white p-4'>
          <div className="relative">
            <div className="absolute top-0 right-0">
              {/* <LongMenu
              options={["Download as CSV", "Download as PDF"]}
              onOptionClick={handleExportBarChart}
              id={1}
            /> */}
            </div>
          </div>
          <p>
            {viewMode === 'graphTEMP'
              ? "Temperature"
              : viewMode === 'graphDO'
                ? "DO"
                : viewMode === 'graphCL'
                  ? "Chlorine"
                  : viewMode === 'graphNI'
                    ? "Nitrates"
                    : viewMode === 'graphPH'
                      ? "PH"
                      : viewMode === 'graphTDS'
                        ? "TDS"
                        : viewMode === 'graphTURB'
                          ? "Turbidity"
                          : ""} data variation with time (between{" "}
            {data.length > 0
              ? data[data.length - 1].timeStamp +
              " to " +
              data[0].timeStamp
              : " "}
            )
          </p>
          <div className="button-group my-4">
            {sensorInfo?.['TEMP']?.status && (
              <button
                onClick={() => handleButtonClick('graphTEMP')}
                className={`px-4 py-1 mr-2 rounded-lg ${viewMode === 'graphTEMP' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
              >
                Temperature
              </button>
            )}
            {sensorInfo?.['DO']?.status && (
              <button
                onClick={() => handleButtonClick('graphDO')}
                className={`px-4 py-1 mr-2  rounded-lg  ${viewMode === 'graphDO' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
              >
                DO
              </button>
            )}
            {sensorInfo?.['CL']?.status && (
              <button
                onClick={() => handleButtonClick('graphCL')}
                className={`px-4 py-1 mr-2 rounded-lg ${viewMode === 'graphCL' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
              >
                Chlorine
              </button>
            )}
            {sensorInfo?.['NI']?.status && (
              <button
                onClick={() => handleButtonClick('graphNI')}
                className={`px-4 py-1 mr-2 rounded-lg ${viewMode === 'graphNI' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
              >
                Nitrates
              </button>
            )}
            {sensorInfo?.['PH']?.status && (
              <button
                onClick={() => handleButtonClick('graphPH')}
                className={`px-4 py-1 mr-2 rounded-lg ${viewMode === 'graphPH' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
              >
                PH
              </button>
            )}
            {sensorInfo?.['TDS']?.status && (
              <button
                onClick={() => handleButtonClick('graphTDS')}
                className={`px-4 py-1 mr-2 rounded-lg ${viewMode === 'graphTDS' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
              >
                TDS
              </button>
            )}

            {sensorInfo?.['TURB']?.status && (
              <button
                onClick={() => handleButtonClick('graphTURB')}
                className={`px-4 py-1 mr-2 rounded-lg ${viewMode === 'graphTURB' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
              >
                TURB
              </button>
            )}


          </div>
          <LineChart
            title=""
            categories={waterQualityLineChart.categories}
            series={waterQualityLineChart.seriesData}
            yAxisTitle="Data"
            xAxisTitle="Time"
            height={450}
          />
        </div>

      </div>


    </>
  )
}

export default DashboardWaterQualityCommon