import { Suspense, useEffect, useState } from 'react'
// import DeviceTable from './components/DeviceTable'
import { useDeviceState } from '../../../app-context/device-context/DeviceState'
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState'
import { setDevice } from '../../../app-context/device-context/DeviceAction'
import { RoleBack } from '../../../types/Role'
// import { useNavigate } from 'react-router-dom'
import Card from '../../../components/card/Card'
import active_device from '../../../assets/end-user-overview/active_device.png'
import inactive_device from '../../../assets/end-user-overview/inactive_device.png'
import communication_error from '../../../assets/end-user-overview/communication_error.png'
import { http } from '../../../utils/helper/http'
import { baseUrlTenant } from '../../../utils/env/env.config'
import DeviceTable from './components/DeviceTable'
import GoogleMapComponent from '../../../components/google-map/GoogleMapComponent'

interface DashBoardData {
  onlineDevicesCount: number;
  offlineDevicesCount: number;
  offlineDevice: number;
  devicesCount: number;
}


const UserDashboard = () => {
  const { dispatch, device } = useDeviceState()
  const { currentUser } = useCurrentUserState()
  const [dashBoardData, setDashboardData] = useState<DashBoardData>({
    onlineDevicesCount: 0,
    offlineDevicesCount: 0,
    offlineDevice: 0,
    devicesCount: 0,
  })
  // const navigate = useNavigate();

  useEffect(() => {
    if (device.device.length === 0) {
      setDevice(dispatch, {
        page: 1,
        limit: 10,
        search: '',
        organizationId: currentUser.organizationId || '',
        vendorId: currentUser.vendorId || '',
        userId: currentUser.role.name === RoleBack.USER ? currentUser._id : '',
        role: currentUser.role.name,
      })
    }
    http(baseUrlTenant + `dashboardStats?userId=${currentUser._id || ''}`)
      .then((response) => {
        setDashboardData(response as DashBoardData)
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  // const handleCardClick = (cardType: string) => {
  //   switch (cardType) {
  //     case 'organization':
  //       navigate("/home/organization-management")
  //       break;
  //     case 'device':
  //     case 'onlineDevice':
  //     case 'offlineDevice':
  //       navigate("/home/device-provision")
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <div className='mb-8 mt-2'>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-1 xl:grid-cols-4 2xl:gap-7.5'>
        <Suspense fallback={<div>Loading...</div>}>
          {/* <Card value={dashBoardData.devicesCount} heading='Total Device' bg_color='#ffffff' img={active_device} /> */}
          <Card value={dashBoardData.onlineDevicesCount} heading='Active Devices' bg_color='#ffffff' img={active_device} />
          <Card value={dashBoardData.offlineDevicesCount} heading='Inactive Devices' bg_color='#ffffff' img={inactive_device} />
          <Card value={0} heading='Communication Error' bg_color='#ffffff' img={communication_error} />
        </Suspense>
      </div>

      <div className="flex flex-col lg:flex-row h-[calc(100vh-3rem)] pt-3 lg:space-x-2 space-y-2 lg:space-y-0">
        <div className="w-full h-full">
          {/* <div className="flex-1 overflow-hidden"> */}
          <DeviceTable />
          {/* </div> */}
        </div>
        <div className="w-full h-full">
          {/* <div className="flex-1 overflow-hidden"> */}
          <GoogleMapComponent />
          {/* </div> */}
        </div>
      </div>

    </div>
  )
}

export default UserDashboard
