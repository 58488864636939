import { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useUserState } from '../../app-context/user-context/UserState'
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState'
import { setUser } from '../../app-context/user-context/UserAction'
import SearchAndActions from '../../components/search-action/SearchAndActions'
import Modal from '../../components/modal/Modal'
import Loader from '../../components/loader/Loader'
import { usePermissionState } from '../../app-context/permission-context/PermissionState'
import { RoleBack } from '../../types/Role'
import downloadFile from '../../utils/csv-pdf-interceptor/download-file'
import { baseUrlUser } from '../../utils/env/env.config'
const UserTable = lazy(() => import('./components/UserTable'));
const LazyAddNewUserForm = lazy(() => import('./components/AddNewUserForm'))

const Users = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.user?.canCreate || false
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { user, dispatch, loading } = useUserState()
  const { currentUser } = useCurrentUserState()
  const [addUserModal, setAddUserModal] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {
    if (currentUser) {
      if ((user.user.length === 0 && currentUser.clientId)) {
        setUser(dispatch, {
          page: 1,
          limit: 10,
          search: '',
          clientId: currentUser.clientId,
          organizationId: currentUser.organizationId || '',
          vendorId: currentUser.vendorId || '',
          role: currentUser.role.name
        })
      }
    }
  }, [currentUser.clientId])
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (currentUser.clientId) {
      timerRef.current = setTimeout((): void => {
        setUser(dispatch, {
          page: 1,
          limit: 10,
          search: event.target.value,
          clientId: currentUser.clientId,
          organizationId: currentUser.organizationId || '',
          vendorId: currentUser.vendorId || '',
          role: currentUser.role.name
        })
      }, 500);
    }
  };

  const handleAddNew = () => {
    setAddUserModal(true);
  };

  const handleExport = async () => {
    const role = currentUser.role.name
    let queryParams = `${currentUser.clientId}/users/export-excel?`
    if (role === RoleBack.ORGANIZATIONADMIN && currentUser.organizationId) {
      queryParams += `organizationId=${currentUser.organizationId}&`;
    } else if (role === RoleBack.SITEADMIN) {
      queryParams += `vendorId=${currentUser.vendorId}&`;
    }
    if (searchTerm !== '' && searchTerm !== undefined) {
      queryParams += `search=${searchTerm}`;
    }
    try {
      await downloadFile(
        baseUrlUser + queryParams,
        (progress) => {
          setDownloadProgress(progress);
        },
        'GET',
        {},
        'user.xlsx'
      );
      setDownloadProgress(0);
    } catch (error) {
      setDownloadProgress(0);
    } finally {
      setDownloadProgress(0);

    }
  };
  return (
    <div className="p-1 py-2">
      {(loading || downloadProgress > 0) && <Loader isBarCircle={(loading || downloadProgress > 0)}/>}

      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <UserTable />
        </Suspense>
      </div>
      <Modal
        // sx={customSx}
        toggleModal={addUserModal}
        onClose={() => {
          setAddUserModal(false)
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddNewUserForm
            onClose={() => {
              setAddUserModal(false)
            }}
            action='ADD_NEW'
          />
        </Suspense>
      </Modal>
    </div>
  )
}

export default Users