import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import SearchAndActions from '../../components/search-action/SearchAndActions';
import Modal from '../../components/modal/Modal';
import { useTicketState } from '../../app-context/ticket-context/TicketState';
import { setTicket } from '../../app-context/ticket-context/TicketAction';
import Loader from '../../components/loader/Loader';
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
const TicketTable = lazy(() => import('./components/TicketTable'));
const LazyAddTicketForm = lazy(() => import('./components/TicketManagementForm'))

const Ticketing = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addTicketModal, setAddTicketModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { tickets, dispatch, loading } = useTicketState()
  const canCreate = true;
  const {currentUser}=useCurrentUserState()

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout((): void => {

    }, 500);
  };

  const handleAddNew = () => {
    setAddTicketModal(true);
  }

  const handleClose = () => {
    setAddTicketModal(false);
  }

  useEffect(() => {
    if (tickets.tickets.length === 0 && currentUser._id) {
      setTicket(dispatch, { page: 1, limit: 10, currentUser: currentUser })
    }
  }, [currentUser._id])


  return (
    <div className="p-1 py-2">
      {loading && <Loader isBarCircle={loading}/>}
      <SearchAndActions searchTerm={searchTerm} onSearchChange={handleSearchChange} onAddNew={handleAddNew} onExport={
        function (): void {
          throw new Error('Function not implemented.');
        }} handelAdd={canCreate} />
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <TicketTable />
        </Suspense>
      </div>

      <Modal toggleModal={addTicketModal} onClose={handleClose}>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddTicketForm
            action='ADD_NEW'
            onClose={handleClose}
          />
        </Suspense>
      </Modal>
    </div>
  )
}

export default Ticketing;
